import React from 'react';

const NAV_ITEMS = [
  { label: 'Groups', href: '/registrar/groups' },
  { label: 'Reports', href: '/registrar/reports' },
  { label: 'Settings', href: '/registrar/settings' },
  { label: 'Vouchers', href: '/registrar/vouchers' },
  { label: 'Payments', href: '/registrar/payments' },
  { label: 'Health Authorities', href: '/registrar/health_authorities' },
  { label: 'Curriculum', href: '/curriculum' },
  { label: 'People', href: '/registrar/people' },
];

function RegistrarNav() {
  return (
    <div className="side-nav bg-dark border-end p-2">
      <ul className="nav flex-column">
        {NAV_ITEMS.map(({ label, href }) => (
          <li key={`${label}-${href}`} className="nav-item">
            <a className="nav-link text-light" href={href}>{label}</a>
          </li>
        ))}
      </ul>
    </div>
  );
}

RegistrarNav.defaultProps = {
};

RegistrarNav.propTypes = {
};

export default RegistrarNav;
