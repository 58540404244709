import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Formik, Form } from 'formik';
import * as yup from 'yup';
import { REDUX_STATUS } from 'lib/constants';
import { REGISTRAR_VOUCHERS_PATH } from 'lib/routerPaths';
import { selectGroupedHealthAuthorities } from 'store/healthAuthorities/selectors';
import { GET_FORM_DATA, CREATE_VOUCHERS } from 'store/vouchers/actions';
import { Button, Spinner } from 'react-bootstrap';
import RegistrarTemplate from 'components/templates/RegistrarTemplate';
import Breadcrumbs from 'components/shared/Breadcrumbs';
import ErrorMessage from 'components/shared/ErrorMessage';
import EmailInput from 'components/shared/EmailInput';
import TextareaField from 'components/shared/FormFields/TextareaField';
import SelectField from 'components/shared/FormFields/SelectField';
import InputField from 'components/shared/FormFields/InputField';
import SwitchField from 'components/shared/FormFields/SwitchField';

const FormSchema = yup.object().shape({
  healthAuth: yup.number().required('Required'),
  courseType: yup.number().required('Required'),
  markAs: yup.string().required('Required'),
  department: yup.string(),
  serviceOrganization: yup.string(),
  authorizerName: yup.string(),
  authorizerEmail: yup.string().email('Invalid email'),
  notes: yup.string(),
  emails: yup.array()
    .of(yup.string().email('invalid emails'))
    .test({
      message: 'Required',
      test: (arr) => arr.length > 0,
    }),
});

const formatOptionLabel = (option, { context }) => {
  if (context === 'menu') return option.label;

  return (
    <span>
      {option.label}
      <span className="ps-2 text-secondary text-small">
        {option.organization}
      </span>
    </span>
  );
};

function VouchersCreatePage() {
  const groupedHealthAuthorities = useSelector((state) => selectGroupedHealthAuthorities(state));
  const { formData, vouchers } = useSelector((state) => state.vouchers);
  const dispatch = useDispatch();
  const history = useHistory();

  const handleSubmit = useCallback((values) => {
    dispatch(CREATE_VOUCHERS.request(
      {
        vouchers: {
          course_type_id: values.courseType,
          emails: values.emails,
          health_authority_id: values.healthAuth,
          mark_training_payment_as: values.markAs,
          department: values.department,
          service_organization: values.serviceOrganization,
          authorizer_name: values.authorizerName,
          authorizer_email: values.authorizerEmail,
          notes: values.notes,
          require_manager: values.requireManager,
        },
      },
    ));
  }, [dispatch]);

  useEffect(() => {
    if (vouchers?.status === REDUX_STATUS.SUCCESS) {
      history.push(REGISTRAR_VOUCHERS_PATH);
    }
  }, [vouchers?.status, history]);

  useEffect(() => {
    if (!formData) {
      dispatch(GET_FORM_DATA.request());
    }
  }, [formData, dispatch]);

  return (
    <RegistrarTemplate>
      <div className="header flush">
        <Breadcrumbs
          navItems={[
            { label: 'Vouchers', href: '/registrar/vouchers' },
            { label: 'Create Vouchers' },
          ]}
        />

        <h1 className="mb-0">Create Vouchers</h1>
      </div>

      <div className="flush px-4 my-4">
        <p className="lead">
          Create new voucher invite codes to allow participants to take the training for free.
        </p>

        <p>
          Fill out the voucher information fields and paste the email addresses for which you want to generate voucher invite codes for below.
          The system will automatically send an email with a special registration link to each of the email addresses you add.
        </p>

        <div className="row mt-4">
          <div className="col">
            <Formik
              onSubmit={handleSubmit}
              validationSchema={FormSchema}
              initialValues={{
                healthAuth: '',
                courseType: '',
                markAs: '',
                department: '',
                serviceOrganization: '',
                authorizerName: '',
                authorizerEmail: '',
                requireManager: false,
                notes: '',
                emails: [],
              }}
            >
              {({ values, isSubmitting }) => (
                <Form>
                  {vouchers.error && <ErrorMessage error={vouchers.error} />}

                  {!formData && (
                    <div className="position-relative mt-4">
                      <div className="position-absolute top-50 start-50 translate-middle">
                        <Spinner animation="border" role="status" />
                      </div>
                    </div>
                  )}

                  {formData && (
                    <>
                      <div className="row">
                        <div className="col-md-6">
                          <SelectField
                            name="healthAuth"
                            label="Health Authority"
                            options={groupedHealthAuthorities}
                            required
                            formatOptionLabel={formatOptionLabel}
                          />

                          <SelectField
                            name="courseType"
                            label="Course"
                            placeholder={!values.healthAuth ? 'Please select a Health Authority first' : undefined}
                            options={values.healthAuth ? formData.healthAuthoritiesCourses[values.healthAuth] : []}
                            disabled={!values.healthAuth}
                            required
                            formatOptionLabel={formatOptionLabel}
                          />

                          <SelectField
                            name="markAs"
                            label="Marked as"
                            options={formData.markTrainingPaymentAs}
                            required
                          />

                          <InputField label="Department" name="department" />
                          <InputField label="Service Organization" name="serviceOrganization" />
                          <InputField label="Manager Name" name="authorizerName" />
                          <InputField label="Manager Email" name="authorizerEmail" />
                          <SwitchField label="Require Manager" name="requireManager" switchPlacement="start" />

                          <TextareaField name="notes" rows="5" />
                        </div>

                        <div className="col-md-6">
                          <EmailInput />
                        </div>
                      </div>

                      <div className="row">
                        <div className="col text-end">
                          <div className="my-3">
                            <Button
                              variant="primary"
                              type="submit"
                              disabled={isSubmitting}
                            >
                              Create Vouchers
                              {isSubmitting && <Spinner size="sm" className="ms-1" animation="border" role="status" />}
                            </Button>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </Form>
              )}

            </Formik>
          </div>
        </div>
      </div>
    </RegistrarTemplate>
  );
}
export default VouchersCreatePage;
