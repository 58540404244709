import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { UserPropType } from 'lib/propTypes';
import { USER_ROLES } from 'lib/constants';

function Roles({ user }) {
  const currentUser = useSelector((state) => state.currentUser);

  const userRoles = useMemo(() => Object.keys(USER_ROLES).reduce((acc, role) => {
    if (user?.[role]) {
      acc.push(USER_ROLES[role]);
    }
    return acc;
  }, []).sort(), [user]);

  if (!currentUser?.hasRegistrarAccess || !userRoles.some((r) => r !== 'Participant')) {
    return null;
  }

  return (
    <div className="mt-3 mt-md-0 mb-3 border rounded py-2">
      <h4 className="py-2 px-3">Roles</h4>

      <ul className="list-unstyled px-3">
        {userRoles.map((role) => (
          <li key={role}>{role}</li>
        ))}
      </ul>
    </div>
  );
}

Roles.propTypes = {
  user: UserPropType.isRequired,
};

export default Roles;
