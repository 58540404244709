import React from 'react';
import { Link } from 'react-router-dom';

function CurriculumNav() {
  return (
    <div className="side-nav bg-dark border-end p-2">
      <ul className="nav flex-column">
        <li className="nav-item">
          <Link className="nav-link text-light" to="/curriculum">Courses</Link>
        </li>
      </ul>
    </div>
  );
}

export default CurriculumNav;
