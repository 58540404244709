import { REPORTING, REPORTING_HARM_REPORT } from 'lib/routerPaths';
import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

function ReportingNav() {
  const { isHarmTeamUser, isSuperAdmin } = useSelector((state) => state.currentUser || {});

  return (
    <div className="side-nav bg-dark border-end p-2">
      <ul className="nav flex-column">
        <li className="nav-item">
          <Link className="nav-link text-light" to={REPORTING}>Dashboard</Link>
        </li>

        {(isHarmTeamUser || isSuperAdmin) && (
          <li className="nav-item">
            <Link className="nav-link text-light" to={REPORTING_HARM_REPORT}>Harm Prevention</Link>
          </li>
        )}
      </ul>
    </div>
  );
}

export default ReportingNav;
